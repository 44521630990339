import React, { useState } from "react";
import axios from "axios";
import ChatBox from "./ChatBox";
import "../styles/ClassCanvas.css"; // Import the CSS file
import { BASE_URL } from "../constants";

function ClassCanvas({ lessonId, lessonLength }) {
  const [counter, setCounter] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [audioUrls, setAudioUrls] = useState([]); // Estado para los URLs del audio
  const [allLessonData, setAllLessonData] = useState([]); // Estado para acumular todas las diapositivas

  const generarClase = (contador) => {
    setLoading(true); // Set loading to true when starting the process

    // First API call
    axios
      .post(
        `${BASE_URL}/lesson/${lessonId}`,
        {
          page: contador,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const imageId = response.data.image_id;
        setLessonData(response.data.lesson); // Save lesson data
        setAllLessonData(prevData => [...prevData, response.data.lesson]); // Add new lesson data to the accumulated data
        console.log(response);

        // Second API call
        axios
          .get(`${BASE_URL}/image/${imageId}`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setImageData(response.data.base64Image); // Save image data
            console.log(response);
            setLoading(false); // Set loading to false after the second API call finishes
          });
        setCounter(counter + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false); // Set loading to false if there's an error
      });
  };

  return (
    <div className="class-container">
      <div className="class-content">
        {loading ? (
          <div className="spinner"></div> // Show spinner when loading is true
        ) : (
          <>
            <div className="button-container">
              {counter > 1 ? (
               <></>
              ) : (
                <button type="submit" onClick={() => generarClase(counter)} className="button">
                  Start Lesson
                </button>
              )}
            </div>
            {imageData && (
              <div className="image-data">
                <img src={`data:image/jpeg;base64,${imageData}`} alt="Lesson" className="centered-image"/>
              </div>
            )}
            {lessonData && (
              <div className="generate-audio-container">
                 <button type="submit" onClick={() => generarClase(counter)} className="button">
                  Next slide
                </button>
                <button type="submit" onClick={() => generarClase(counter -1)}>
                  Re-generate slide
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {allLessonData.length > 0 && (
        <div className="lesson-and-chat">
          <ChatBox lessonData={allLessonData[allLessonData.length - 1]} audioUrls={audioUrls} />
        </div>
      )}
    </div>
  );
}

export default ClassCanvas;