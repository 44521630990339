import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/ChatBox.css"; // Import the CSS file
import { BASE_URL } from "../constants";

function ChatBox(props) {
  const { lessonData, audioUrls = [] } = props; // Default to an empty array if audioUrls is undefined
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  // Effect to add new lesson data to messages when lessonData changes
  useEffect(() => {
    if (lessonData) {
      setMessages(prevMessages => [...prevMessages, { text: lessonData, sender: "bot" }]);
    }
  }, [lessonData]);

  const handleSend = () => {
    if (input.trim()) {
      const userMessage = { text: input, sender: "user" };
      setMessages([...messages, userMessage]);
      setInput(""); // Clear the input field

      // Send the message to the server
      axios.post(`${BASE_URL}/question`, { content: input })
        .then((response) => {
          const botMessage = { text: response.data.response, sender: "bot" };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        })
        .catch((error) => {
          console.error("Error:", error);
          const errorMessage = { text: "Error sending message", sender: "bot" };
          setMessages((prevMessages) => [...prevMessages, errorMessage]);
        });
    }
  };

  // Función para dividir el texto en partes más pequeñas
  const splitText = (text, maxLength) => {
    const parts = [];
    let start = 0;
    while (start < text.length) {
      let end = start + maxLength;
      if (end < text.length && text[end] !== " ") {
        while (end > start && text[end] !== " ") {
          end--;
        }
      }
      parts.push(text.substring(start, end));
      start = end;
    }
    return parts;
  };

  const generarAudio = (text) => {
    const maxLength = 4096; // Límite máximo de caracteres
    const parts = splitText(text, maxLength);
    const audioPromises = parts.map(part => {
      return axios
        .post(
          `${BASE_URL}/audio`,
          { text: part },
          {
            responseType: 'blob', // Important for handling binary data
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
          return url;
        })
        .catch((error) => {
          console.error("Error generating audio:", error);
          return null;
        });
    });

    Promise.all(audioPromises).then(urls => {
      const validUrls = urls.filter(url => url !== null);
      setMessages(prevMessages => [
        ...prevMessages,
        ...validUrls.map(url => ({ text: url, sender: "audio" }))
      ]);
    });
  };

  return (
    <div className="chat-box">
      <div className="chat-header">Bloom Academy</div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.sender}`}>
            {msg.sender === "audio" ? (
              <audio controls src={msg.text} />
            ) : (
              msg.text
            )}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={handleSend}>Send</button>
        {lessonData && (
          <button onClick={() => generarAudio(lessonData)}>Generate Audio</button>
        )}
      </div>
    </div>
  );
}

export default ChatBox;