import React, { useState } from "react";
import axios from "axios";
import "../styles/styles.css";
import ClassCanvas from "./ClassCanvas";
import { BASE_URL } from "../constants";

function FileUpload() {
  const [textoAncla, setTextoAncla] = useState(null);
  const [archivoPdf, setArchivoPdf] = useState(null);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lessonId, setLessonId] = useState("");

  const handleTextoAncla = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTextoAncla(file);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }
  };

  const handleArchivoPdf = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setArchivoPdf(file);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    } else {
      setError("Please upload a valid PDF file.");
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!textoAncla || !archivoPdf) {
      setError("Please upload both files.");
      setShowError(true);
      setTimeout(() => setShowError(false), 3000); // Ocultar después de 3 segundos
      return;
    }
    setError("");
    setShowError(false);
    setLoading(true); // Mostrar spinner
    const formData = new FormData();
    formData.append("texto_ancla", textoAncla);
    formData.append("archivo_pdf", archivoPdf);

    axios
      .post(`${BASE_URL}/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);

        setLessonId(response.data.lesson._id);
        setResponse(response.data.lesson);
        setLoading(false); // Ocultar spinner
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Error creating the Lesson.");
        setLoading(false); // Ocultar spinner
        setShowError(true);
        setTimeout(() => setShowError(false), 3000); // Ocultar después de 3 segundos
      });
  };

  return (
    <div className="app-container">
      {loading && <div className="spinner"></div>}
      {showPopup && <div className="popup">File selected successfully!</div>}
      {showError && <div className="error">{error}</div>}
      {lessonId && <ClassCanvas lessonId={response._id} lessonLength = {response.lesson_length} />}
      <div className="file-upload-container">
        <form onSubmit={handleFormSubmit} className="file-upload-form">
          <div className="file-upload-item">
            <label htmlFor="baseText" data-label="Upload base Text" />
            <input id="baseText" type="file" onChange={handleTextoAncla} />
            {textoAncla && <p>File: {textoAncla.name}</p>}
          </div>
          <div className="file-upload-item">
            <label htmlFor="pdf" data-label="Upload PDF" />
            <input
              id="pdf"
              type="file"
              accept="application/pdf"
              onChange={handleArchivoPdf}
            />
            {archivoPdf && <p>File: {archivoPdf.name}</p>}
          </div>
          <div className="file-upload-item">
            <button type="submit">Upload Files</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FileUpload;